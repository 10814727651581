import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"
import Parser from "html-react-parser"

// import app components
import GallerySlider from "components/gallerySlider"
import Edges from "components/edges"
import Spacer from "components/spacer"
import Textarea from "components/textarea"
import Form from "components/form"
import * as theme from "theme"
import formatPrice from "utils/formatPrice"

const BoatSingle = (props) => {
  const {
    data: {
      wpBoat: { title, boat, availabilities, boatTypes }
    }
  } = props

  const [tab, setTab] = useState(0)

  const status = availabilities?.nodes.find((p) => p.databaseId === 26)
    ? "Pending"
    : availabilities?.nodes?.[0]?.name

  const boatType = boatTypes?.nodes && boatTypes.nodes[0].name

  const items = []

  if (boat.year) {
    items.push({ label: "Year", value: boat.year })
  }

  if (boat.length) {
    items.push({ label: "Length", value: boat.length })
  }

  if (boat.location) {
    items.push({ label: "Location", value: boat.location })
  }

  if (boat.boatHullMaterial) {
    items.push({ label: "Hull Material", value: boat.boatHullMaterial })
  }

  if (boat.boatName) {
    items.push({ label: "Boat Name", value: boat.boatName })
  }

  const tanks = []

  if (boat.waterTankCount && boat.waterTankCapacity) {
    tanks.push({
      label: "Fresh Water Tanks",
      value: `${boat.waterTankCount} (${boat.waterTankCapacity})`
    })
  }

  if (boat.fuelTankCount && boat.fuelTankCapacity) {
    tanks.push({
      label: "Fuel Tanks",
      value: `${boat.fuelTankCount} (${boat.fuelTankCapacity})`
    })
  }

  if (boat.holdingTankCount && boat.holdingTankCapacity) {
    tanks.push({
      label: "Holding Tanks",
      value: `${boat.holdingTankCount} (${boat.holdingTankCapacity})`
    })
  }

  const dimensions = []

  if (boat.lengthOverall) {
    dimensions.push({
      label: "LOA",
      value: boat.lengthOverall
    })
  }

  if (boat.beam) {
    dimensions.push({
      label: "Beam",
      value: boat.beam
    })
  }

  if (boat.bridgeClearance) {
    dimensions.push({
      label: "Bridge Clearance",
      value: boat.bridgeClearance
    })
  }

  const engines = []

  if (boat.numberOfEngines) {
    engines.push({
      label: "Number of Engines",
      value: boat.numberOfEngines
    })
  }

  if (boat.totalEngineHours) {
    engines.push({
      label: "Total Engine Hours",
      value: boat.totalEngineHours
    })
  }

  if (boat.totalEnginePower) {
    engines.push({
      label: "Total Engine Power",
      value: boat.totalEnginePower
    })
  }

  const enginesDetail = []

  {
    boat.engines &&
      boat.engines.map((o, i) => {
        const el = (
          <>
            <Typography variant="caption" children={`Engine ${i + 1}`} />
            {o.make && <Typography children={`Make: ${o.make}`} />}
            {o.model && <Typography children={`Model: ${o.model}`} />}
            {o.year && <Typography children={`Year: ${o.year}`} />}
            {o.boatEngineLocation && (
              <Typography children={`Location: ${o.boatEngineLocation}`} />
            )}
            {o.enginePower && (
              <Typography children={`Power: ${o.enginePower}`} />
            )}
            {o.fuel && <Typography children={`Fuel: ${o.fuel}`} />}
            {o.hours && <Typography children={`Hours: ${o.hours}`} />}
            {o.propellerType && (
              <Typography children={`Propeller Type: ${o.propellerType}`} />
            )}
            {o.type && <Typography children={`Type: ${o.type}`} />}
          </>
        )

        return enginesDetail.push(el)
      })
  }

  return (
    <>
      <Edges size="sm">
        <Spacer
          mt={{ xs: 80, md: 100 }}
          pt={{ xs: 30, sm: 50 }}
          pb={{ xs: 50, md: 100 }}
        >
          {status && (
            <Status status={status} variant="caption" children={status} />
          )}
          <Typography variant="h2" children={Parser(title)} />

          <Spacer mb={{ xs: 30, md: 50 }}>
            {boat.location && (
              <Typography variant="caption" children={Parser(boat.location)} />
            )}
          </Spacer>

          {boat?.images && (
            <Spacer mb={{ xs: 30, md: 50 }}>
              <GalleryContainer>
                <GallerySlider backgroundSize="contain" images={boat?.images} />
              </GalleryContainer>
            </Spacer>
          )}

          <Spacer mb={30}>
            <Tabs>
              <Tab
                active={tab === 0}
                onClick={() => setTab(0)}
                children="Description"
              />
              <Tab
                active={tab === 1}
                onClick={() => setTab(1)}
                children="View Full Specifications"
              />
            </Tabs>
          </Spacer>

          <TabPanel value={tab} index={0}>
            <Spacer mb={20}>
              <Typography variant="h3" children={`Overview`} />
            </Spacer>

            <Spacer mb={30}>
              <Grid container>
                {boatType && (
                  <Grid item xs={12} sm={6}>
                    <Spacer pr={20} mb={10}>
                      <Typography variant="caption" children={`Boat Type`} />
                      <Typography children={boatType} />
                    </Spacer>
                  </Grid>
                )}

                {items.map((o, i) => {
                  return (
                    <Grid key={i} item xs={12} sm={6}>
                      <Spacer pr={20} mb={10}>
                        <Typography variant="caption" children={o.label} />
                        <Typography children={o.value} />
                      </Spacer>
                    </Grid>
                  )
                })}
              </Grid>
            </Spacer>

            {boat.price && (
              <Spacer pr={20} mb={40}>
                <Typography variant="caption" children={`Price`} />
                <div>
                  {status === "Sold" ? (
                    <Status
                      status={status}
                      variant="caption"
                      children={status}
                    />
                  ) : (
                    <Typography
                      children={
                        boat.price.includes("CAD")
                          ? formatPrice(boat.price)
                          : boat.price
                      }
                    />
                  )}
                </div>
              </Spacer>
            )}

            {boat.text && (
              <>
                <Spacer mb={20}>
                  <Typography variant="h3" children={`Description`} />
                </Spacer>
                <StyledTextarea content={boat.text} />
              </>
            )}
          </TabPanel>

          <TabPanel value={tab} index={1}>
            {dimensions.length > 0 && (
              <>
                <Spacer mb={20}>
                  <Typography variant="h3" children={`Dimensions`} />
                </Spacer>
                <Spacer mb={40}>
                  <Grid container>
                    {dimensions.map((o, i) => {
                      return (
                        <Grid key={i} item xs={12} sm={6}>
                          <Spacer pr={20} mb={10}>
                            <Typography variant="caption" children={o.label} />
                            <Typography children={o.value} />
                          </Spacer>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Spacer>
              </>
            )}

            {engines.length > 0 && (
              <>
                <Spacer mb={20}>
                  <Typography variant="h3" children={`Engines`} />
                </Spacer>
                <Spacer mb={40}>
                  <Grid container>
                    {engines.map((o, i) => {
                      return (
                        <Grid key={i} item xs={12} sm={6}>
                          <Spacer pr={20} mb={10}>
                            <Typography variant="caption" children={o.label} />
                            <Typography children={o.value} />
                          </Spacer>
                        </Grid>
                      )
                    })}
                  </Grid>

                  <Grid container>
                    {enginesDetail.map((o, i) => {
                      return (
                        <Grid key={i} item xs={12} sm={6}>
                          <Spacer pr={20} mb={10}>
                            {o}
                          </Spacer>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Spacer>
              </>
            )}

            {tanks.length > 0 && (
              <>
                <Spacer mb={20}>
                  <Typography variant="h3" children={`Tanks`} />
                </Spacer>
                <Spacer mb={40}>
                  <Grid container>
                    {tanks.map((o, i) => {
                      return (
                        <Grid key={i} item xs={12} sm={6}>
                          <Spacer pr={20} mb={10}>
                            <Typography variant="caption" children={o.label} />
                            <Typography children={o.value} />
                          </Spacer>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Spacer>
              </>
            )}

            {boat.additionalDescription && (
              <>
                <Spacer mb={20}>
                  <Typography variant="h3" children={`Boat Description`} />
                </Spacer>
                <StyledTextarea content={boat.additionalDescription} />
              </>
            )}
          </TabPanel>

          {status !== "Sold" && (
            <>
              <Spacer mt={60} mb={40}>
                <Typography variant="h2" children={`Contact Us`} />
              </Spacer>

              <Form
                formId={2}
                buttonText={`Contact Broker`}
                hideTitle
                hiddenFields={[{ id: 5, content: title }]}
              />
            </>
          )}
        </Spacer>
      </Edges>
    </>
  )
}

const Status = styled(Typography)`
  && {
    display: inline-block;
    padding: 4px 8px;
    margin-bottom: 6px;
    color: #fff;
    background: ${({ status }) =>
      status === "Sold" ? theme.colors.error : theme.colors.primary};
  }
`

const GalleryContainer = styled.div`
  width: 100%;
  height: 400px;
  background: ${theme.colors.secondary};
`

const Tabs = styled.div`
  display: flex;
`

const Tab = styled(Typography)`
  && {
    width: 50%;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    border: 2px solid ${theme.colors.primary};
    background: ${({ active }) =>
      active ? theme.colors.primary : "transparent"};
    color: ${({ active }) => (active ? "#fff" : theme.colors.secondary)};
  }
`

const TabPanel = styled.div`
  display: ${({ value, index }) => (value !== index ? "none" : "block")};
`

const StyledTextarea = styled(Textarea)`
  && {
    * {
      color: ${theme.colors.text.light} !important;
    }

    strong {
      color: ${theme.colors.text.dark} !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &:not(:first-child) {
        margin-top: 1.5rem;
      }
    }

    p {
      img {
        margin-bottom: 0;
      }
    }

    div {
      max-width: 100%;
    }

    img {
      display: flex;
      width: 100%;
    }

    figure {
      width: 100% !important;
    }
  }
`

export const CollectionQuery = graphql`
  query DefaultSingleBoat($id: String!) {
    wpBoat(id: { eq: $id }) {
      id
      title
      uri
      boat {
        additionalDescription
        beam
        boatHullMaterial
        boatName
        bridgeClearance
        engines {
          boatEngineLocation
          enginePower
          fieldGroupName
          fuel
          hours
          make
          model
          propellerType
          type
          year
        }
        fuelTankCapacity
        fuelTankCount
        holdingTankCapacity
        holdingTankCount
        length
        lengthOverall
        location
        make
        model
        numberOfEngines
        price
        text
        totalEngineHours
        totalEnginePower
        waterTankCapacity
        waterTankCount
        yachtWorldId
        year
        images {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                formats: AUTO
                layout: FULL_WIDTH
                quality: 90
                placeholder: BLURRED
              )
            }
          }
        }
      }
      availabilities {
        nodes {
          name
          id
          databaseId
        }
      }
      boatTypes {
        nodes {
          databaseId
          name
        }
      }
    }
  }
`

export default BoatSingle
